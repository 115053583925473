import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { ProductContentProps } from '../types';

const ProductContent: React.FC<React.PropsWithChildren<ProductContentProps>> = (props) => {
  const numberFormatter = Intl.NumberFormat('da-DK');

  return (
    <Row className="product-main-simple">
      <Col xs={2} sm={1} className="my-auto px-0">
        {props.showImage === true && props.product.vendor_products_image && props.product.vendor_products_image.length !== 0 ? (
          <label>
            <img width={65} height={65} src={props.product.vendor_products_image} alt="alt" />
          </label>
        ) : null}
      </Col>
      <Col xs={2} sm={2} className="my-auto d-none d-sm-flex sku-wrapper">
        {props.showSku && <label>{props.product.vendor_products_sku}</label>}
        {props.showEan && <small>{props.product.vendor_products_ean}</small>}
      </Col>
      <Col xs={6} sm={4} className="my-auto">
        {props.showName && <label>{props.product.vendor_products_name}</label>}
      </Col>
      <Col xs={2} sm={2} className="my-auto d-none d-sm-block">
        {props.showManufactor && <label>{props.product.vendor_products_manufactor}</label>}
      </Col>
      <Col xs={2} sm={props.showPrice ? 2 : 1} className="my-auto d-none d-sm-block">
        {props.showPrice && <label>{numberFormatter.format(props.product.vendor_products_price)} DKK</label>}
      </Col>
      <Col xs={4} sm={!props.showPrice ? 2 : 1} className="my-auto d-flex justify-content-end">
        {props.showButton && <Button variant="primary">{props.buttonText}</Button>}
      </Col>
    </Row>
  );
};

export default ProductContent;
