import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProductInterface, CategoryInterface, ChildVariation } from 'pages/import-products/shared/types';
import { VariableEditProps } from './types';
import { useSelectedProducts } from 'contexts/selected-products';

const EditVariableProducts: React.FC<React.PropsWithChildren<VariableEditProps>> = (props) => {
  const numberFormatter = Intl.NumberFormat('da-DK');
  const { selectedProducts, setSelectedProducts } = useSelectedProducts();
  const [selectedVariations, setSelectedVariations] = useState<number[]>([]);

  useEffect(() => {
    if (!props.product.product_variations) {
      return;
    }
    if (selectedProducts.some((product) => product.vendor_products_id === props.product.vendor_products_id)) {
      const variationIds = props.product.product_variations
        .map((variation) => variation.vendor_product_variations_id)
        .filter((id): id is number => id !== undefined);

      setSelectedVariations(variationIds);
    } else {
      setSelectedVariations([]);
    }
  }, [selectedProducts, props.product]);

  const handleProductClick = (product: ProductInterface) => (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    const isProductSelected = selectedProducts.some((selected) => selected.vendor_products_id === product.vendor_products_id);

    setSelectedProducts((currentProducts) =>
      isProductSelected ? currentProducts.filter((selected) => selected.vendor_products_id !== product.vendor_products_id) : [...currentProducts, product],
    );
  };

  const handleVariationClick = (variation: ChildVariation) => (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    const variationId = variation.vendor_product_variations_id;
    if (variationId === undefined) return;

    setSelectedVariations((currentVariations) =>
      currentVariations.includes(variationId) ? currentVariations.filter((id) => id !== variationId) : [...currentVariations, variationId],
    );
  };

  const onProductSelect = (tmpProduct: ProductInterface): void => {
    props.setProductUnderEdit(tmpProduct);
    props.setIsSimpleModalOpen(true);
  };

  const onVariableProductSelect = (tmpProduct: ProductInterface, variantId: number | undefined): void => {
    if (!variantId) {
      return;
    }

    props.setProductUnderEdit(tmpProduct);
    props.setVariationId(variantId);
    props.setIsVariableModalOpen(true);
  };

  const getProductStatus = (): string => {
    if (props.product.vendor_products_status) {
      return props.product.vendor_products_status === 'draft' ? 'Kladde' : 'Udgiv';
    } else {
      return (props.product.vendor_products_status = 'draft');
    }
  };

  return (
    <Row className="product" key={props.product.vendor_products_id}>
      <Col sm={12} className="product-main-variable" onClick={() => onProductSelect(props.product)}>
        <Row>
          <Col xs={2} sm={1} className="my-auto mx-auto d-flex justify-content-center">
            <input
              type="checkbox"
              className="bigger-checkbox"
              checked={selectedProducts.some((p) => p.vendor_products_id === props.product.vendor_products_id)}
              onClick={handleProductClick(props.product)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => event.stopPropagation()}
              readOnly={true}
            />
          </Col>

          <Col xs={2} sm={1} className="my-auto">
            <img width={50} height={50} src={props.product.vendor_products_image} alt="alt" />
          </Col>

          <Col xs={2} sm={2} className="my-auto d-none d-sm-flex sku-wrapper">
            <label>{props.product.product_variations?.[0].vendor_product_variations_sku}</label>
            <small>{props.product.product_variations?.[0].vendor_product_variations_ean}</small>
          </Col>

          <Col xs={4} sm={3} className="my-auto">
            <label>{props.product.vendor_products_name}</label>
          </Col>

          <Col xs={2} sm={2} className="my-auto d-none d-sm-block">
            <label>
              {props.product.vendor_products_category instanceof Array
                ? props.product.vendor_products_category.map((category: string | CategoryInterface, index, array) => {
                    const separator = index < array.length - 1 ? ', ' : '';
                    return typeof category === 'object' ? category.name + separator : category + separator;
                  })
                : ''}
            </label>
          </Col>

          <Col xs={4} sm={2} className="my-auto"></Col>

          <Col xs={2} sm={1} className="my-auto d-none d-sm-block">
            <label>{getProductStatus()}</label>
          </Col>

          <Col xs={1} sm={1} className="product-edit-stock my-auto d-none d-sm-none d-md-none d-lg-none d-xl-block"></Col>
        </Row>
      </Col>

      {props.product.product_variations &&
        props.product.product_variations.map((variation) => (
          <Col
            sm={12}
            className="product-variable"
            key={variation.vendor_product_variations_id}
            onClick={() => onVariableProductSelect(props.product, variation.vendor_product_variations_id)}
            checked={selectedProducts.some((product) => product.vendor_products_id === props.product.vendor_products_id)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => event.stopPropagation()}
            disabled
          >
            <Row>
              <Col
                xs={2}
                sm={1}
                className="my-auto mx-auto d-flex justify-content-center"
                onClick={(event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation()}
              >
                <input
                  type="checkbox"
                  checked={variation.vendor_product_variations_id !== undefined && selectedVariations.includes(variation.vendor_product_variations_id)}
                  onClick={() => handleVariationClick(variation)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => event.stopPropagation()}
                  disabled={!selectedProducts.some((product) => product.vendor_products_id === props.product.vendor_products_id)}
                  readOnly={true}
                />
              </Col>

              <Col xs={2} sm={1} className="my-auto d-none d-sm-block">
                {variation.vendor_product_variations_image && variation.vendor_product_variations_image.length !== 0 ? (
                  <img width={50} height={50} src={variation.vendor_product_variations_image} alt="alt" />
                ) : null}
              </Col>

              <Col xs={2} sm={2} className="my-auto d-none d-sm-flex sku-wrapper">
                <label>{variation.vendor_product_variations_sku}</label>
                <small>{variation.vendor_product_variations_ean}</small>
              </Col>

              <Col xs={6} sm={3} className="my-auto">
                <label>{variation.vendor_product_variations_name}</label>
              </Col>

              <Col xs={2} sm={2} className="my-auto d-none d-sm-block"></Col>

              <Col xs={4} sm={1} className="my-auto">
                <label>{numberFormatter.format(variation.vendor_product_variations_price)} DKK</label>
              </Col>

              <Col xs={2} sm={1} className="my-auto d-none d-sm-block"></Col>

              <Col xs={1} sm={1} className="product-edit-stock my-auto d-none d-sm-none d-md-none d-lg-none d-xl-block">
                <label>{variation.vendor_product_variations_stock} stk.</label>
                <label className={variation.vendor_product_variations_stock && variation.vendor_product_variations_stock > 0 ? 'instock' : 'out-of-stock'}>
                  {variation.vendor_product_variations_stock && variation.vendor_product_variations_stock > 0 ? 'På lager' : 'Ikke på lager'}
                </label>
              </Col>
            </Row>
          </Col>
        ))}
    </Row>
  );
};

export default EditVariableProducts;
