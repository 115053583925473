import React, { useEffect, useState } from 'react';
import { categoryInterface } from '../../shared/types';
// IndexedDb
import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDB } from '../../shared/db';
//Api
import { getVendorProductsCategories } from 'Api';
//Styles
import { Col, Row, Button, Spinner } from 'react-bootstrap';

export const SelectCategoriesPage: React.FC = () => {
  const [categories, setCategories] = useState<categoryInterface[]>([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [sortingFilter, setSortingFilter] = useState<string>('asc');
  const [currentPage] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [visibleCount, setVisibleCount] = useState<number>(20);
  const [addBtnLoading, setAddBtnLoading] = useState<boolean>(false);

  const selectedVendors = useLiveQuery(() => dexieDB.vendors.toArray());

  const selectedCategories = useLiveQuery(() => dexieDB.categoies.toArray());

  useEffect(() => {
    if (!selectedVendors || selectedVendors.length === 0) {
      return;
    }

    const getVendorProductsCategoriesHandler = async (): Promise<void> => {
      const vendorIds: number[] = [];

      if (selectedVendors) {
        for await (const vendor of selectedVendors) {
          if (vendor.vendors_id) {
            vendorIds.push(vendor.vendors_id);
          }
        }
      }

      const response = await getVendorProductsCategories(sortingFilter, searchFilter.trim(), vendorIds, currentPage, totalResults);

      if (response) {
        setCategories(
          response.data.results.map((product: categoryInterface) => {
            return {
              vendor_products_category: product.vendor_products_category,
              product_count: product.product_count,
            };
          }),
        );
        setTotalResults(response.data.total);
      }
    };

    getVendorProductsCategoriesHandler();
  }, [selectedVendors, searchFilter, sortingFilter, currentPage, totalResults]);

  const isSelected = (category: string): boolean => {
    if (selectedCategories) {
      return selectedCategories.some((tmpCategory) => tmpCategory.vendor_products_category === category);
    }

    return false;
  };

  const handleCategorySelect = async (category: string): Promise<void> => {
    if (isSelected(category) === true) {
      await dexieDB.categoies.delete(category);
    } else {
      await dexieDB.categoies.add({ vendor_products_category: category });
    }
  };

  const handleSelectAll = async (): Promise<void> => {
    const bulkCreate: Pick<categoryInterface, 'vendor_products_category'>[] = [];
    setAddBtnLoading(true);
    for await (const category of categories) {
      if (isSelected(category.vendor_products_category) === false) {
        bulkCreate.push({ vendor_products_category: category.vendor_products_category });
      }
    }

    await dexieDB.categoies.bulkAdd(bulkCreate);
    setAddBtnLoading(false);
  };

  const handleDeselectAll = async (): Promise<void> => {
    await dexieDB.categoies.clear();
  };

  const handleShowMore = () => {
    setVisibleCount((count) => count + 10);
  };

  const handleShowAll = () => {
    setVisibleCount(totalResults);
  };

  return (
    <>
      <Row className="heading-intro">
        <Col>
          <h1>Kategori</h1>
          <h5>Vælg de kategorier du vil importere produkter fra</h5>
        </Col>
      </Row>

      <Row className="mt-5 option-panel">
        <Col sm={2}>
          <select value={sortingFilter} className="form-select" onChange={(e) => setSortingFilter(e.target.value)} aria-label="Default select example">
            <option value="asc">A-Z</option>
            <option value="desc">Z-A</option>
          </select>
        </Col>
        <Col sm={{ span: 5, offset: 1 }}>
          <input type="search" placeholder="Søg" id="form1" className="form-control" value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
        </Col>
        <Col sm={4} className="button-wrapper">
          <Button className="button-orange" onClick={handleDeselectAll}>
            Fravælg alle
          </Button>
          <Button onClick={handleSelectAll}>{addBtnLoading ? <Spinner animation="border" role="status" size="sm"></Spinner> : 'Tilføj alle'}</Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs={4} sm={8}>
          <label>
            <b>Kategori</b>
          </label>
        </Col>
        <Col xs={4} sm={2}>
          <label>
            <b>Antal produkter</b>
          </label>
        </Col>
        <Col xs={4} sm={2} className="d-flex justify-content-end">
          <label>
            <b>Tilføjet til import</b>
          </label>
        </Col>
      </Row>

      <Row>
        <Col sm={12} className="category mt-3">
          {categories.slice(0, visibleCount).map((category) => (
            <Row key={category.vendor_products_category} onClick={() => handleCategorySelect(category.vendor_products_category)}>
              <Col xs={6} sm={8} className="my-auto">
                <p>{category.vendor_products_category}</p>
              </Col>
              <Col xs={2} sm={2} className="my-auto">
                <p>{category.product_count}</p>
              </Col>
              <Col xs={4} sm={2} className="my-auto d-flex justify-content-end">
                <span className="icons">
                  {isSelected(category.vendor_products_category) === true ? (
                    <Button variant="primary">
                      <i className="fas fa-check"></i>
                    </Button>
                  ) : (
                    <Button variant="primary">Tilføj</Button>
                  )}
                </span>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row className="mb-1 mt-4">
        <Col>
          {visibleCount < totalResults && (
            <>
              <Button onClick={handleShowMore} variant="primary">
                Vis mere
              </Button>
              <Button className="mx-2" onClick={handleShowAll} variant="primary">
                Vis alle ({totalResults})
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
