import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { VariableProductContentProps } from '../types';

const VariableProductContent: React.FC<React.PropsWithChildren<VariableProductContentProps>> = (props) => {
  const numberFormatter = Intl.NumberFormat('da-DK');

  return (
    <Row>
      <Col xs={2} sm={1} className="my-auto">
        {props.showImage === true && props.variation.vendor_product_variations_image && props.variation.vendor_product_variations_image.length !== 0 ? (
          <label>
            <img width={50} height={50} src={props.variation.vendor_product_variations_image} alt="alt" />
          </label>
        ) : null}
      </Col>
      <Col xs={2} sm={2} className="my-auto d-none d-sm-flex sku-wrapper">
        {props.showSku && <label>{props.variation.vendor_product_variations_sku}</label>}
        {props.showEan && <small>{props.variation.vendor_product_variations_ean}</small>}
      </Col>
      <Col xs={6} sm={4} className="my-auto">
        {props.showName && <label>{props.variation.vendor_product_variations_name}</label>}
      </Col>
      <Col xs={2} sm={2} className="my-auto d-none d-sm-block">
        {props.showAttribute && (
          <label>
            {props.variation.vendor_product_variations_attributes}: {props.variation.vendor_product_variations_attributes_value}
          </label>
        )}
      </Col>
      <Col xs={2} sm={props.showPrice ? 2 : 1} className="my-auto d-none d-sm-block">
        {props.showPrice && <label>{numberFormatter.format(props.variation.vendor_product_variations_price)} DKK</label>}
      </Col>
      <Col xs={4} sm={!props.showPrice ? 2 : 1} className="my-auto d-flex justify-content-end">
        {props.showButton && <Button variant="primary">{props.buttonText}</Button>}
      </Col>
    </Row>
  );
};

export default VariableProductContent;
