import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProductInterface, CategoryInterface } from 'pages/import-products/shared/types';
import { ProductEditProps } from './types';
import { useSelectedProducts } from 'contexts/selected-products';

const EditSimpleProducts: React.FC<React.PropsWithChildren<ProductEditProps>> = (props) => {
  const onProductSelect = (tmpProduct: ProductInterface): void => {
    props.setProductUnderEdit(tmpProduct);
    props.setIsSimpleModalOpen(true);
  };

  const numberFormatter = Intl.NumberFormat('da-DK');
  const { selectedProducts, setSelectedProducts } = useSelectedProducts();

  const toggleProductSelection = (product: ProductInterface) => {
    const isProductSelected = selectedProducts.some((selected) => selected.vendor_products_id === product.vendor_products_id);

    if (isProductSelected) {
      setSelectedProducts((current) => current.filter((selected) => selected.vendor_products_id !== product.vendor_products_id));
    } else {
      setSelectedProducts((current) => [...current, product]);
    }
  };

  const handleProductClick = (product: ProductInterface) => (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    toggleProductSelection(product);
  };

  const getProductStatus = (): string => {
    if (props.product.vendor_products_status) {
      return props.product.vendor_products_status === 'draft' ? 'Kladde' : 'Udgiv';
    } else {
      return (props.product.vendor_products_status = 'draft');
    }
  };

  const isInStock = (): boolean => {
    if (props.product.vendor_products_stock) {
      return props.product.vendor_products_stock > 0;
    }
    return false;
  };

  return (
    <Row className="product-simple" key={props.product.vendor_products_id}>
      <Col sm={12} className="product-container" onClick={() => onProductSelect(props.product)}>
        <Row>
          <Col xs={2} sm={1} className="my-auto mx-auto d-flex justify-content-center">
            <input
              type="checkbox"
              className="bigger-checkbox"
              onClick={handleProductClick(props.product)}
              checked={selectedProducts.some((p) => p.vendor_products_id === props.product.vendor_products_id)}
              readOnly
            />
          </Col>

          <Col xs={2} sm={1} className="my-auto">
            {props.product.vendor_products_image && props.product.vendor_products_image.length !== 0 ? (
              <img width={50} height={50} src={props.product.vendor_products_image} alt="alt" />
            ) : null}
          </Col>

          <Col xs={2} sm={2} className="my-auto d-none d-sm-flex sku-wrapper">
            <label>{props.product.vendor_products_sku}</label>
            <small>{props.product.vendor_products_ean}</small>
          </Col>

          <Col xs={4} sm={3} className="my-auto">
            <label>{props.product.vendor_products_name}</label>
          </Col>

          <Col xs={2} sm={2} className="my-auto d-none d-sm-block">
            <label>
              {props.product.vendor_products_category instanceof Array
                ? props.product.vendor_products_category.map((category: string | CategoryInterface, index, array) => {
                    const separator = index < array.length - 1 ? ', ' : '';
                    return typeof category === 'object' ? category.name + separator : category + separator;
                  })
                : ''}
            </label>
          </Col>

          <Col xs={4} sm={1} className="my-auto">
            <label>{numberFormatter.format(props.product.vendor_products_price)} DKK</label>
          </Col>

          <Col xs={2} sm={1} className="my-auto d-none d-sm-block">
            <label>{getProductStatus()}</label>
          </Col>

          <Col xs={1} sm={1} className="product-edit-stock my-auto d-none d-sm-none d-md-none d-lg-none d-xl-block">
            <label>{props.product.vendor_products_stock} stk.</label>
            <label className={isInStock() ? 'instock' : 'out-of-stock'}>{isInStock() ? 'På lager' : 'Ikke på lager'}</label>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EditSimpleProducts;
