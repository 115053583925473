import React, { useEffect, useState } from 'react';
// IndexedDb
import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDB } from '../../shared/db';
//Models
import { ProductInterface, CategoryInterface } from '../../shared/types';
import { Button, Col, Row } from 'react-bootstrap';
//API
import { getWebshopProductCategories } from 'Api';
//Components
import { SimpleEditProducts, VariableEditProducts, SimpleProductModal, VariationProductModal } from 'components/products';
import { useSelectedProducts } from 'contexts/selected-products';
import CustomToast from 'components/toast';

export const EditProductsPage: React.FC = () => {
  const [editedProducts, setEditedProducts] = useState<ProductInterface[]>([]);
  const [isSimpleModalOpen, setIsSimpleModalOpen] = useState(false);
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const [productUnderEdit, setProductUnderEdit] = useState<ProductInterface | null>();
  const [variationId, setVariationId] = useState<number>(0);
  const [webshopCategories, setWebshopCategories] = useState<CategoryInterface[]>([]);
  const [sortField, setSortField] = useState<keyof ProductInterface | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [hoverField, setHoverField] = useState<string | null>(null);
  const [selectedChange, setSelectedChange] = useState<string>('masserediger');
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState<string>('');
  const [currentPage] = useState<number>(1);
  const [resultsPerPage] = useState<number>(20);
  const { selectedProducts, setSelectedProducts } = useSelectedProducts();
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [visibleCount, setVisibleCount] = useState<number>(20);

  const [vejlPrice, setVejlPrice] = useState<string>('');
  const [discountPrice, setDiscountPrice] = useState<string>('');
  const [costPrice, setCostPrice] = useState<string>('');
  const [discount, setDiscount] = useState<string>('');
  const [discountType, setDiscountType] = useState<string>('');
  const [stock, setStock] = useState<string>('');
  const [categorySelected, setCategorySelected] = useState<number>();

  const [toastActive, setToastActive] = useState(false);
  const [recentlyUpdated, setRecentlyUpdated] = useState<number[]>([]);

  const editedProductsCount = useLiveQuery(() => dexieDB.editedProducts.count());
  const allEditedProducts = useLiveQuery(() => {
    return dexieDB.editedProducts.toArray().then((products) => {
      if (sortField === 'vendor_products_sku') {
        products.sort((a, b) => {
          const skuA = a.product_variations?.[0]?.vendor_product_variations_sku ?? a.vendor_products_sku;
          const skuB = b.product_variations?.[0]?.vendor_product_variations_sku ?? b.vendor_products_sku;

          if (sortOrder === 'asc') {
            return skuA.localeCompare(skuB);
          } else {
            return skuB.localeCompare(skuA);
          }
        });
      } else if (sortField) {
        products.sort((a, b) => {
          if (sortOrder === 'asc') {
            return String(a[sortField]).localeCompare(String(b[sortField]));
          } else {
            return String(b[sortField]).localeCompare(String(a[sortField]));
          }
        });
      }

      return products;
    });
  }, [sortField, sortOrder]);

  const handleSort = (field: string) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field as keyof ProductInterface);
      setSortOrder('asc');
    }

    localStorage.setItem('editSortField', field);
    localStorage.setItem('editSortOrder', sortOrder === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    const savedSortField = localStorage.getItem('editSortField');
    const savedSortOrder = localStorage.getItem('editSortOrder');

    if (savedSortField) {
      setSortField(savedSortField as keyof ProductInterface);
    }

    if (savedSortOrder) {
      setSortOrder(savedSortOrder as 'asc' | 'desc');
    }
  }, []);

  useEffect(() => {
    if (!allEditedProducts) {
      return;
    }

    setEditedProducts(allEditedProducts);
  }, [allEditedProducts, currentPage, resultsPerPage]);

  useEffect(() => {
    let isMounted = true;

    const getWebshopProductCategoriesHandler = async (): Promise<void> => {
      setIsLoadingCategories(true);
      const response = await getWebshopProductCategories();

      if (isMounted) {
        if (response) {
          setWebshopCategories(response.data);
        }
      }

      setIsLoadingCategories(false);
    };

    getWebshopProductCategoriesHandler();

    return () => {
      isMounted = false;
    };
  }, []);

  // Filtrerer både på kategori og søgning
  useEffect(() => {
    if (!allEditedProducts) return;

    const filteredProducts = allEditedProducts.filter((product) => {
      const matchesSearchTerm = searchTerm
        ? product.vendor_products_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.vendor_products_sku?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.vendor_products_ean?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.vendor_products_price?.toString().includes(searchTerm.toLowerCase())
        : true;

      const matchesCategory = category
        ? Array.isArray(product.vendor_products_category)
          ? product.vendor_products_category.some((cat) => {
              if (cat && typeof cat === 'object' && cat.id) {
                return parseInt(`${cat.id}`) === parseInt(`${category}`);
              }

              return false;
            })
          : product.vendor_products_category
          ? product.vendor_products_category.includes(category)
          : false
        : true;

      return matchesSearchTerm && matchesCategory;
    });

    // Opdaterer state med de produkter, som skal vises på den nuværende side
    setEditedProducts(filteredProducts);
  }, [allEditedProducts, currentPage, resultsPerPage, searchTerm, category]);

  function clearAll(clearSelected = true, clearType = '') {
    if (clearSelected) {
      setSelectedChange('masserediger');
    }

    if (clearType == 'all') {
      setSelectedProducts([]);
    }

    setVejlPrice('');
    setDiscountPrice('');
    setDiscount('');
    setDiscountType('');
    setCostPrice('');
    setStock('');
    setSearchTerm('');
    setCategory('');
    setCategorySelected(0);
  }

  function changeSelected(event: React.ChangeEvent<HTMLSelectElement>) {
    clearAll(false);
    setSelectedChange(event.target.value);
  }

  function handleCategoryChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setCategory(event.target.value);
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleMarkAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedProducts(editedProducts);
    } else {
      setSelectedProducts([]);
    }
  };

  const activateToast = () => {
    setToastActive(true);
    setTimeout(() => {
      setToastActive(false);
    }, 4000);
  };

  const performMassChange = async () => {
    if (!selectedProducts.length) {
      return;
    }

    if (selectedChange === 'masserediger' || (!vejlPrice && !discountPrice && !costPrice && !discount && !stock && !categorySelected)) {
      clearAll(true);
      return;
    }

    const updatedProducts = selectedProducts.map((product) => {
      const updatedProduct = { ...product };

      // Opdaterer simple og eventuelle sub-produkter for hver valgt ændring
      if (selectedChange === 'prisændringer') {
        updatedProduct.vendor_products_price = vejlPrice ? parseFloat(vejlPrice) : product.vendor_products_price;
        updatedProduct.vendor_products_sales_price = discountPrice ? parseFloat(discountPrice) : product.vendor_products_sales_price;
        updatedProduct.vendor_products_cost_price = costPrice ? parseFloat(costPrice) : product.vendor_products_cost_price;

        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => ({
            ...variation,
            vendor_product_variations_price: vejlPrice ? parseFloat(vejlPrice) : variation.vendor_product_variations_price,
            vendor_product_variations_sales_price: discountPrice ? parseFloat(discountPrice) : variation.vendor_product_variations_sales_price,
            vendor_product_variations_cost_price: costPrice ? parseFloat(costPrice) : variation.vendor_product_variations_cost_price,
          }));
        }
      } else if (selectedChange === 'lagerstatus') {
        updatedProduct.vendor_products_stock = stock ? parseInt(stock, 10) : product.vendor_products_stock;

        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => ({
            ...variation,
            vendor_product_variations_stock: stock ? parseInt(stock, 10) : variation.vendor_product_variations_stock,
          }));
        }
      } else if (selectedChange === 'procent') {
        const discountPercentage = discount ? parseFloat(discount) / 100 : 0;

        if (discountType === 'stigning') {
          updatedProduct.vendor_products_price = product.vendor_products_price + product.vendor_products_price * discountPercentage;
        } else {
          updatedProduct.vendor_products_price = product.vendor_products_price - product.vendor_products_price * discountPercentage;
        }

        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => {
            if (discountType === 'stigning') {
              variation.vendor_product_variations_price =
                variation.vendor_product_variations_price + variation.vendor_product_variations_price * discountPercentage;
            } else {
              variation.vendor_product_variations_price =
                variation.vendor_product_variations_price - variation.vendor_product_variations_price * discountPercentage;
            }
            return variation;
          });
        }
      } else if (selectedChange === 'tilføj-kategori' && categorySelected) {
        const categoryObject = webshopCategories.find((category) => parseInt(category.id) === categorySelected);

        if (categoryObject) {
          let updatedCategories: CategoryInterface[] = [];

          if (Array.isArray(updatedProduct.vendor_products_category)) {
            updatedCategories = updatedProduct.vendor_products_category as CategoryInterface[];

            // Vi tilføjer kun, hvis kategorien ikke allerede er tilføjet
            if (!updatedCategories.some((category) => category.id === categoryObject.id)) {
              updatedCategories.push(categoryObject);
            }
          } else {
            updatedCategories = [categoryObject];
          }

          updatedProduct.vendor_products_category = updatedCategories;
        }
      }

      return updatedProduct;
    });

    // Opdatering af Dexie med de ændrede produkter
    for (const product of updatedProducts) {
      if (product.vendor_products_id !== undefined) {
        await dexieDB.editedProducts.update(product.vendor_products_id, product);
      }
    }

    // Gemmer de opdaterede produkter i state for at visualisere dem
    const updatedProductIds = updatedProducts.map((p) => p.vendor_products_id);
    setRecentlyUpdated(updatedProductIds);

    setTimeout(() => {
      setRecentlyUpdated([]);
    }, 4000);

    setSelectedProducts(updatedProducts);
    setEditedProducts(updatedProducts);
    activateToast();
    clearAll(true);
  };

  function calculateTotalMarked() {
    let total = 0;

    selectedProducts.forEach((product) => {
      total += 1;

      if (product.product_variations) {
        total += product.product_variations.length;
      }
    });

    return total;
  }

  const handleShowMore = () => {
    setVisibleCount((count) => count + 10);
  };

  const handleShowAll = () => {
    setVisibleCount(editedProductsCount ? editedProductsCount : 0);
  };

  return (
    <>
      <div style={{ position: 'fixed', top: '90px', right: '25px' }} className="toast-box">
        <CustomToast
          show={toastActive}
          message={calculateTotalMarked() + (calculateTotalMarked() === 1 ? ' produkt' : ' produkter') + ' blev opdateret.'}
          onClose={() => setToastActive(false)}
          duration={3500}
          title={'Ændringerne er gemt.'}
          type={'toast-success'}
        />
      </div>

      <Row className="heading-intro">
        <Col>
          <h1>Produkter</h1>
          <h5>Klik på en produktlinje for at redigere.</h5>
        </Col>
      </Row>

      <span>{'Total af ' + calculateTotalMarked() + ' produkter er markeret.'}</span>

      <Row className="mt-4 mobile">
        <Col xs={'auto'}>
          <input type="text" className="form-control" placeholder="Søg efter produkt" value={searchTerm} onChange={handleSearchChange} />
        </Col>

        <Col xs={'auto'}>
          <select className="form-select" aria-label="Category filter" value={category} onChange={handleCategoryChange} disabled={isLoadingCategories}>
            {isLoadingCategories ? (
              <option>Henter Kategorier...</option>
            ) : (
              <>
                <option value="">Alle kategorier</option>
                {webshopCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </>
            )}
          </select>
        </Col>

        <Col xs={'auto'}>
          <select className="form-select" aria-label="Select form" value={selectedChange || ''} onChange={changeSelected}>
            <option value="masserediger">Masseredigering</option>
            <option value="prisændringer">Prisændringer</option>
            <option value="procent">Procentvis salgspris</option>
            <option value="lagerstatus">Lagerstatus</option>
            <option value="tilføj-kategori">Tilføj kategori</option>
          </select>
        </Col>

        {selectedChange && selectedChange == 'prisændringer' && (
          <Col xs={'auto'}>
            <input
              type="number"
              min={0}
              step={50}
              className="form-control"
              placeholder={'Vejl. pris'}
              value={vejlPrice}
              onChange={(e) => setVejlPrice(e.target.value)}
            />
          </Col>
        )}

        {selectedChange && selectedChange == 'prisændringer' && (
          <Col xs={'auto'}>
            <input
              type="number"
              className="form-control"
              min={0}
              step={50}
              placeholder={'Tilbudspris'}
              value={discountPrice}
              onChange={(e) => setDiscountPrice(e.target.value)}
            />
          </Col>
        )}

        {selectedChange && selectedChange == 'prisændringer' && (
          <Col xs={'auto'}>
            <input
              type="number"
              min={0}
              step={50}
              className="form-control"
              placeholder={'Kostpris'}
              value={costPrice}
              onChange={(e) => setCostPrice(e.target.value)}
            />
          </Col>
        )}

        {selectedChange && selectedChange == 'procent' && (
          <Col xs={2}>
            <input
              type="number"
              className="form-control"
              min={0}
              max={100}
              placeholder={'Indtast ændring i procent'}
              value={discount}
              onChange={(e) => {
                Number(e.target.value) <= 100 ? setDiscount(e.target.value) : setDiscount('');
              }}
            />
          </Col>
        )}

        {selectedChange && selectedChange == 'procent' && (
          <Col xs={2}>
            <select className="form-select" aria-label="Procent form" onChange={(e) => setDiscountType(e.target.value)}>
              <option value="">Vælg type</option>
              <option value="stigning">Prisstigning</option>
              <option value="prisfald">Prisfald</option>
            </select>
          </Col>
        )}

        {selectedChange && selectedChange == 'lagerstatus' && (
          <Col xs={2}>
            <input
              type="number"
              min={0}
              className="form-control"
              placeholder={'Indtast lagerbeholdning'}
              value={stock}
              onChange={(e) => setStock(e.target.value)}
            />
          </Col>
        )}

        {selectedChange && selectedChange == 'tilføj-kategori' && (
          <Col xs={'auto'}>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setCategorySelected(parseInt(e.target.value))}
              disabled={isLoadingCategories}
            >
              {isLoadingCategories ? (
                <option>Henter Kategorier...</option>
              ) : (
                <>
                  <option value="">Alle kategorier</option>
                  {webshopCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </>
              )}
            </select>
          </Col>
        )}

        <div style={{ width: 'auto' }}>
          <button className="btn button-secondary" onClick={() => clearAll(true, 'all')}>
            Ryd alt
          </button>
          <button className="btn btn-primary ms-2" onClick={performMassChange} disabled={selectedChange === 'masserediger'}>
            Anvend
          </button>
        </div>
      </Row>

      <Row className="mt-5 mb-4">
        <Col xs={2} sm={1} className="d-flex justify-content-center text-center">
          <label className="mobile-checkbox d-flex">
            <input type="checkbox" className="me-2" onChange={handleMarkAll} />
            <b>Vælg</b>
          </label>
        </Col>

        <Col xs={2} sm={1}>
          <label>
            <b>Billede</b>
          </label>
        </Col>

        <Col xs={2} sm={2} className="d-none d-sm-block">
          <label
            className="sorting-label"
            onClick={() => handleSort('vendor_products_sku')}
            onMouseEnter={() => setHoverField('vendor_products_sku')}
            onMouseLeave={() => setHoverField(null)}
          >
            <b>Varenummer</b>
            {sortField === 'vendor_products_sku' && (sortOrder === 'asc' ? <label>▲</label> : <label className="arrow-down">▲</label>)}
            {hoverField === 'vendor_products_sku' && sortField !== 'vendor_products_sku' && <span>▲</span>}
          </label>
        </Col>

        <Col xs={4} sm={3}>
          <label
            className="sorting-label"
            onClick={() => handleSort('vendor_products_name')}
            onMouseEnter={() => setHoverField('vendor_products_name')}
            onMouseLeave={() => setHoverField(null)}
          >
            <b>Produktnavn</b>
            {sortField === 'vendor_products_name' && (sortOrder === 'asc' ? <label>▲</label> : <label className="arrow-down">▲</label>)}
            {hoverField === 'vendor_products_name' && sortField !== 'vendor_products_name' && <span>▲</span>}
          </label>
        </Col>

        <Col xs={2} sm={2} className="d-none d-sm-block">
          <label
            className="sorting-label"
            onClick={() => handleSort('vendor_products_category')}
            onMouseEnter={() => setHoverField('vendor_products_category')}
            onMouseLeave={() => setHoverField(null)}
          >
            <b>Kategori</b>
            {sortField === 'vendor_products_category' && (sortOrder === 'asc' ? <label>▲</label> : <label className="arrow-down">▲</label>)}
            {hoverField === 'vendor_products_category' && sortField !== 'vendor_products_category' && <span>▲</span>}
          </label>
        </Col>

        <Col xs={4} sm={1}>
          <label
            className="sorting-label"
            onClick={() => handleSort('vendor_products_price')}
            onMouseEnter={() => setHoverField('vendor_products_price')}
            onMouseLeave={() => setHoverField(null)}
          >
            <b>Pris</b>
            {sortField === 'vendor_products_price' && (sortOrder === 'asc' ? <label>▲</label> : <label className="arrow-down">▲</label>)}
            {hoverField === 'vendor_products_price' && sortField !== 'vendor_products_price' && <span>▲</span>}
          </label>
        </Col>

        <Col xs={2} sm={1} className="d-none d-sm-block">
          <label>
            <b>Status</b>
          </label>
        </Col>

        <Col xs={1} sm={1} className="d-none d-sm-none d-md-none d-lg-none d-xl-block">
          <label>
            <b>Lager</b>
          </label>
        </Col>
      </Row>

      <Row>
        <Col sm={12} className="edit-product-container">
          {editedProducts &&
            editedProducts.slice(0, visibleCount).map((product) => {
              if (product.product_variations && product.product_variations.length !== 0) {
                const isRecentlyUpdated = recentlyUpdated.includes(product.vendor_products_id);
                const rowClass = isRecentlyUpdated ? 'green-row' : '';

                return (
                  <div key={product.vendor_products_id} className={rowClass}>
                    <VariableEditProducts
                      key={product.vendor_products_id}
                      product={product}
                      selectedProducts={selectedProducts}
                      setIsSimpleModalOpen={setIsSimpleModalOpen}
                      setIsVariableModalOpen={setIsVariableModalOpen}
                      setProductUnderEdit={setProductUnderEdit}
                      setVariationId={setVariationId}
                    />
                  </div>
                );
              } else {
                const isRecentlyUpdated = recentlyUpdated.includes(product.vendor_products_id);
                const rowClass = isRecentlyUpdated ? 'green-row' : '';

                return (
                  <div key={product.vendor_products_id} className={rowClass}>
                    <SimpleEditProducts
                      key={product.vendor_products_id}
                      product={product}
                      selectedProducts={selectedProducts}
                      setIsSimpleModalOpen={setIsSimpleModalOpen}
                      setProductUnderEdit={setProductUnderEdit}
                    />
                  </div>
                );
              }
            })}
        </Col>
      </Row>

      <Row className="mb-1 mt-4">
        <Col>
          {visibleCount < (editedProductsCount ? editedProductsCount : 0) && (
            <>
              <Button onClick={handleShowMore} variant="primary">
                Vis mere
              </Button>
              <Button className="mx-2" onClick={handleShowAll} variant="primary">
                Vis alle ({editedProductsCount})
              </Button>
            </>
          )}
        </Col>
      </Row>

      <SimpleProductModal
        productUnderEdit={productUnderEdit}
        setProductUnderEdit={setProductUnderEdit}
        isSimpleModalOpen={isSimpleModalOpen}
        setIsSimpleModalOpen={setIsSimpleModalOpen}
        webshopCategories={webshopCategories}
      />
      <VariationProductModal
        productUnderEdit={productUnderEdit}
        isVariationModalOpen={isVariableModalOpen}
        setIsVariationModalOpen={setIsVariableModalOpen}
        variationId={variationId}
        setVariationId={setVariationId}
      />
    </>
  );
};
