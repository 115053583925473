import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row, Modal, Form } from 'react-bootstrap';
//Multiselect dropdown
import Select, { OnChangeValue } from 'react-select';

import { SimpleModalProps } from './types';
import { ProductInterface, CategoryInterface, ExtraAttributes } from '../../../pages/import-products/shared/types';
import { dexieDB } from '../../../pages/import-products/shared/db';

const SimpleProductModal: React.FC<React.PropsWithChildren<SimpleModalProps>> = (props) => {
  const [attributes, setAttributes] = useState<ExtraAttributes[]>([]);
  const [productStock, setProductStock] = useState<string>(
    props.productUnderEdit && props.productUnderEdit.vendor_products_stock ? props.productUnderEdit.vendor_products_stock.toString() : '',
  );

  const updateAttributes = async () => {
    const attributeData: ExtraAttributes[] = [];

    if (props.productUnderEdit) {
      if (props.productUnderEdit.vendor_products_properties && typeof props.productUnderEdit.vendor_products_properties === 'string') {
        const properties = JSON.parse(props.productUnderEdit.vendor_products_properties);

        for await (const property of properties) {
          for await (const [key, value] of Object.entries(property)) {
            attributeData.push({
              name: key,
              value: value as string,
            });
          }
        }
      }
    }

    setAttributes(attributeData);
  };

  useEffect(() => {
    updateAttributes();
  }, [props.productUnderEdit, props.isSimpleModalOpen]);

  useEffect(() => {
    if (!props.productUnderEdit) {
      return;
    }

    let ProductStockCount = 0;

    if (productStock) {
      ProductStockCount = Number(productStock);
    }

    props.setProductUnderEdit({
      ...props.productUnderEdit,
      ['vendor_products_stock']: ProductStockCount,
    });
  }, [productStock]);

  useEffect(() => {
    if (!props.productUnderEdit) {
      return;
    }

    setProductStock(`${Number(props.productUnderEdit.vendor_products_stock)}`);
  }, [props.productUnderEdit]);

  const handleProductSave = async (product: ProductInterface | null | undefined): Promise<void> => {
    if (!product || !product.vendor_products_id) {
      return;
    }

    const product_variations_properties = attributes.map((attribute) => {
      return { [attribute.name]: attribute.value };
    });

    const updatedProduct = {
      ...product,
      vendor_products_properties: JSON.stringify(product_variations_properties),
    };

    await dexieDB.editedProducts.update(product.vendor_products_id, updatedProduct);
    props.setIsSimpleModalOpen(false);
  };

  const handleProductValueChanged = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>): void => {
    if (!props.productUnderEdit) {
      return;
    }

    props.setProductUnderEdit({
      ...props.productUnderEdit,
      [event.target.id]: event.target.value,
    });
  };

  const handleEditCategories = (newValue: OnChangeValue<CategoryInterface, true>): void => {
    if (!props.productUnderEdit) {
      return;
    }

    props.setProductUnderEdit({
      ...props.productUnderEdit,
      vendor_products_category: newValue as CategoryInterface[],
    });
  };

  const handleAddAttribute = () => {
    const newAttribute = { name: '', value: '' };
    setAttributes((currentAttributes) => [...currentAttributes, newAttribute]);
  };

  const handleRemoveAttribute = (index: number) => {
    setAttributes((currentAttributes) => currentAttributes.filter((_, currentIndex) => currentIndex !== index));
  };

  const handleAttributeChange = (index: number, key: keyof ExtraAttributes, value: string) => {
    setAttributes((currentAttributes) => currentAttributes.map((attr, i) => (i === index ? { ...attr, [key]: value } : attr)));
  };

  const getProductStatus = (): string => {
    if (!props.productUnderEdit || !props.productUnderEdit.vendor_products_status) {
      return 'draft';
    }

    return props.productUnderEdit.vendor_products_status;
  };

  const getProductSalesPrice = (): number => {
    if (!props.productUnderEdit || !props.productUnderEdit.vendor_products_sales_price) {
      return 0;
    }

    return props.productUnderEdit.vendor_products_sales_price;
  };

  const getProductCostPrice = (): number => {
    if (!props.productUnderEdit || !props.productUnderEdit.vendor_products_cost_price) {
      return 0;
    }

    return props.productUnderEdit.vendor_products_cost_price;
  };

  const getStockStatus = (): 'instock' | 'outofstock' => {
    if (props.productUnderEdit?.vendor_products_stock === 0) {
      return 'outofstock';
    } else {
      return 'instock';
    }
  };

  return (
    <Modal show={props.isSimpleModalOpen} className="edit-product-modal" backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          <h1>Rediger produkt</h1>
          <h5>Indsæt dine egne værdier i felterne nedenfor</h5>
        </Modal.Title>
        <Button className="button-orange me-2" onClick={() => props.setIsSimpleModalOpen(false)}>
          Luk
        </Button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} lg={6}>
                {props.productUnderEdit && props.productUnderEdit.vendor_products_image && props.productUnderEdit.vendor_products_image.length !== 0 ? (
                  <img className="modal-product-image" src={props.productUnderEdit.vendor_products_image} alt="alt" />
                ) : null}
              </Col>
              <Col xs={12} lg={6}>
                <Row className="input-field">
                  <Form.Group controlId={'vendors_name'}>
                    <Form.Label>Forhandler</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Forhandler"
                      value={props.productUnderEdit?.vendors_name}
                      onChange={handleProductValueChanged}
                      disabled
                    />
                  </Form.Group>
                </Row>

                {props.productUnderEdit?.product_variations?.length === 0 && (
                  <Row>
                    <Col sm={6}>
                      <Row className="input-field">
                        <Form.Group controlId={'vendor_products_sku'}>
                          <Form.Label>Varenummer / SKU</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Varenummer"
                            value={props.productUnderEdit?.vendor_products_sku}
                            onChange={handleProductValueChanged}
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col sm={6}>
                      <Row className="input-field">
                        <Form.Group controlId={'vendor_products_ean'}>
                          <Form.Label>Stregkode / EAN</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Stregkode / EAN"
                            value={props.productUnderEdit?.vendor_products_ean}
                            onChange={handleProductValueChanged}
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                  </Row>
                )}

                <Row className="input-field">
                  <Form.Group controlId={'vendor_products_name'}>
                    <Form.Label>Produktnavn</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Indtast produktnavn"
                      value={props.productUnderEdit?.vendor_products_name}
                      onChange={handleProductValueChanged}
                    />
                  </Form.Group>
                </Row>
                <Row className="input-field">
                  <Form.Group controlId={'vendor_products_slug'}>
                    <Form.Label>URL / Slug</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Indtast produkt slug"
                      value={props.productUnderEdit?.vendor_products_slug}
                      onChange={handleProductValueChanged}
                    />
                  </Form.Group>
                </Row>

                {/* Price */}
                {props.productUnderEdit?.product_variations?.length === 0 && (
                  <Row>
                    <Col sm={4}>
                      <Row className="input-field">
                        <Form.Group controlId={'vendor_products_price'}>
                          <Form.Label>Vejl. pris </Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            step={50}
                            placeholder="Indtast vejledende pris"
                            value={props.productUnderEdit?.vendor_products_price}
                            onChange={handleProductValueChanged}
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <Row className="input-field">
                        <Form.Group controlId={'vendor_products_sales_price'}>
                          <Form.Label>Tilbudspris</Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            step={50}
                            placeholder="Indtast tilbudspris"
                            value={getProductSalesPrice()}
                            onChange={handleProductValueChanged}
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <Row className="input-field">
                        <Form.Group controlId={'vendor_products_cost_price'}>
                          <Form.Label>Kostpris</Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            step={50}
                            placeholder="Indtast kostpris"
                            value={getProductCostPrice()}
                            onChange={handleProductValueChanged}
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                    <Form.Text className="text-muted">* Priser opgivet i € er ganget med kurs 7,5</Form.Text>
                  </Row>
                )}

                {/* Stock */}
                {props.productUnderEdit?.product_variations?.length === 0 && (
                  <Row>
                    <Col sm={6}>
                      <Row className="input-field">
                        <Form.Group controlId={'vendor_products_stock'}>
                          <Form.Label>Lager</Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            placeholder="Indtast lagerbeholdning"
                            value={productStock}
                            onChange={(event) => setProductStock(event.target.value)}
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col sm={6}>
                      <Row className="input-field">
                        <Form.Group controlId={'vendor_products_stock_status'}>
                          <Form.Label>Lager status</Form.Label>
                          <Form.Control
                            type="text"
                            value={getStockStatus() === 'instock' ? 'På lager' : 'Ikke på lager'}
                            disabled
                            style={{ backgroundColor: getStockStatus() === 'instock' ? 'rgb(119 227 119)' : 'rgb(255 138 138)' }}
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                  </Row>
                )}

                {/* Status + Weight */}
                <Row>
                  <Col sm={6}>
                    <Row className="input-field">
                      <Form.Group controlId={'vendor_products_status'}>
                        <Form.Label>Status</Form.Label>
                        <Form.Select aria-label="status" value={getProductStatus()} onChange={handleProductValueChanged}>
                          <option value="draft">Kladde</option>
                          <option value="publish">Udgiv</option>
                        </Form.Select>
                      </Form.Group>
                    </Row>
                  </Col>
                  {props.productUnderEdit?.product_variations?.length === 0 && (
                    <Col sm={6}>
                      <Row className="input-field">
                        <Form.Group controlId={'vendor_products_weight'}>
                          <Form.Label>Vægt</Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            step={5}
                            placeholder="Indtast vægt"
                            value={props.productUnderEdit?.vendor_products_weight}
                            onChange={handleProductValueChanged}
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                  )}
                </Row>

                {/* Description */}
                <Row className="input-field">
                  <Form.Group controlId={'vendor_products_excerpt'}>
                    <Form.Label>Kort beskrivelse</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Indtast kort beskrivelse"
                      value={props.productUnderEdit?.vendor_products_excerpt}
                      onChange={handleProductValueChanged}
                    />
                  </Form.Group>
                </Row>
                <Row className="input-field">
                  <Form.Group controlId={'vendor_products_content'}>
                    <Form.Label>Beskrivelse</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Indtast beskrivelse"
                      value={props.productUnderEdit?.vendor_products_content}
                      onChange={handleProductValueChanged}
                    />
                  </Form.Group>
                </Row>

                {/* Category */}
                <Row className="input-field">
                  <Form.Group controlId={'vendor_products_category'}>
                    <Form.Label>Kategori</Form.Label>
                    <Form.Text className="d-block text-muted">Hvis en kategori ikke findes, skal du oprette den i webshoppen</Form.Text>
                    <Select
                      id="vendor_products_category"
                      options={props.webshopCategories}
                      placeholder="Vælg kategori"
                      isSearchable={true}
                      isMulti={true}
                      getOptionValue={(option: CategoryInterface) => option.id}
                      getOptionLabel={(option: CategoryInterface) => `${option.name}`}
                      onChange={handleEditCategories}
                      noOptionsMessage={() => `${'Ingen kategorier'}`}
                      defaultValue={props.productUnderEdit?.vendor_products_category as CategoryInterface[]}
                    />
                  </Form.Group>
                </Row>

                {/* Attributes */}
                {props.productUnderEdit?.product_variations?.length === 0 && (
                  <>
                    <Form.Label>Opret Attributter</Form.Label>
                    {attributes.map((attribute, index) => (
                      <Row key={index} className="mb-2">
                        <Col sm={5}>
                          <Form.Group controlId={`attribute-name-${index}`}>
                            <Form.Control
                              type="text"
                              placeholder="Indtast navn"
                              value={attribute.name}
                              onChange={(e) => handleAttributeChange(index, 'name', e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={5}>
                          <Form.Group controlId={`attribute-value-${index}`}>
                            <Form.Control
                              type="text"
                              placeholder="Indtast værdi"
                              value={attribute.value}
                              onChange={(e) => handleAttributeChange(index, 'value', e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={2} className="d-flex">
                          <Button variant="outline-danger" onClick={() => handleRemoveAttribute(index)}>
                            Fjern
                          </Button>
                        </Col>
                      </Row>
                    ))}

                    <Row>
                      <Col xs={12} className="d-flex justify-content-left">
                        <Button variant="outline-dark" onClick={handleAddAttribute}>
                          Tilføj attribut
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Container fluid>
            <Row>
              <Col xs={6} className="d-flex justify-content-end">
                <Button className="button-orange" onClick={() => props.setIsSimpleModalOpen(false)}>
                  Afbryd
                </Button>
              </Col>
              <Col xs={6}>
                <Button onClick={() => handleProductSave(props.productUnderEdit)}>Gem</Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SimpleProductModal;
